<template>
  <b-overlay :show="isLoading">
    <b-container class="registration-card-container">
      <base-form
        :config="formConfig.steps"
        :disabled="isClosed"
        v-model="registrationData"
        @submit="doSubmit"
      />
    </b-container>
  </b-overlay>
</template>

<script>
import BaseForm from "@/components/BaseForm.vue";
import WorkshopReservationsService from "@/services/WorkshopReservationsService";

export default {
  components: { BaseForm },
  data() {
    return {
      form: this.$route.params.id,
      isLoading: false,
      registrationData: {},
      isDisabled: false,
    };
  },
  computed: {
    formConfig() {
      const formConfig = this.$store.getters["Registration/form"];
      if (!formConfig) {
        return formConfig;
      }
      return formConfig[this.locale];
    },
    isClosed() {
      return (
        this.formConfig?.isClosed ||
        (this.formConfig?.isClosedWhenCompleted && this.formCompleted)
      );
    },
    formCompleted() {
      return this.currentUser[`additional_info_form${this.form}_completed`];
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
    locale() {
      return this.$root.$i18n.locale;
    },
  },
  methods: {
    fillMissing() {
      for (const step of this.formConfig.steps) {
        for (const el of step.elements) {
          if (
            !el.field ||
            el.type == "static-text" ||
            this.registrationData[el.field]
          ) {
            continue;
          }
          this.registrationData[el.field] = "";
        }
      }
    },
    async doSubmit() {
      this.isLoading = true;
      this.fillMissing();
      const result = await this.checkWorkshopReservations();
      await this.$store.dispatch("Auth/addFormRegistration", {
        lang: this.locale,
        data: this.registrationData,
      });
      if (!result) {
        this.isLoading = false;
        return;
      }
      if (this.registrationData.complete?.length > 0) {
        this.isDisabled = true;
      }
      this.isLoading = false;
      this.$router.push({ path: this.formConfig.link });
    },
    async checkWorkshopReservations() {
      for (const formStep of this.formConfig.steps) {
        for (const element of formStep.elements) {
          if (element.type === "workshop") {
            const field = element.field;
            const value = this.registrationData[field];
            if (!value) {
              continue;
            }
            try {
              await this.submitWorkshopReservation(field, value);
            } catch (e) {
              const result = await this.$bvModal.msgBoxOk(
                `Your workshop for ${element.label} is fully booked already`,
                {
                  okVariant: "primary",
                  noCloseOnBackdrop: true,
                  centered: true,
                }
              );
              return false;
            }
          }
        }
      }
      return true;
    },
    async submitWorkshopReservation(field, workshopId) {
      const result = await WorkshopReservationsService.create({
        registration_field: field,
        workshop_id: workshopId,
      });
      if (result.error) {
        throw result.error;
      }
    },
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch(
      "Registration/fetchParsedById",
      "form" + this.form
    );
    if (this.currentUser["additional_info_form" + this.form] !== null) {
      this.registrationData = this.currentUser[
        "additional_info_form" + this.form
      ];
    }
    this.registrationData.form = this.form;
    if (this.registrationData.complete?.length > 0) {
      this.isDisabled = true;
    }
    this.isLoading = false;
  },
};
</script>
